// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-engage-agent-selection-index-mdx": () => import("/opt/build/repo/src/pages/engage-agent-selection/index.mdx" /* webpackChunkName: "component---src-pages-engage-agent-selection-index-mdx" */),
  "component---src-pages-engage-customer-index-mdx": () => import("/opt/build/repo/src/pages/engage-customer/index.mdx" /* webpackChunkName: "component---src-pages-engage-customer-index-mdx" */),
  "component---src-pages-engage-dashboard-index-mdx": () => import("/opt/build/repo/src/pages/engage-dashboard/index.mdx" /* webpackChunkName: "component---src-pages-engage-dashboard-index-mdx" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pivotus-design-system-index-mdx": () => import("/opt/build/repo/src/pages/pivotus-design-system/index.mdx" /* webpackChunkName: "component---src-pages-pivotus-design-system-index-mdx" */),
  "component---src-pages-port-js": () => import("/opt/build/repo/src/pages/port.js" /* webpackChunkName: "component---src-pages-port-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-zero-welcome-index-mdx": () => import("/opt/build/repo/src/pages/zero-welcome/index.mdx" /* webpackChunkName: "component---src-pages-zero-welcome-index-mdx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

